body {
  font-family: 'Work Sans', sans-serif;
  -webkit-backface-visibility: hidden;
-webkit-perspective: 1000;
-webkit-transform: translate3d(0,0,0);
-webkit-transform: translateZ(0);
backface-visibility: hidden;
perspective: 1000;
transform: translate3d(0,0,0);
transform: translateZ(0);
}

.downArrow {
  stroke-dasharray: 1150;
  stroke-dashoffset: 1150;
  stroke-opacity: 0.6;
  fill-opacity: 0.6;
  fill-rule:evenodd;
  clip-rule:evenodd;
  fill:#0f0;
  transition: all 0.5s ease;
 }
 .downArrow:hover {
   stroke-dasharray: 1150;
   stroke-dashoffset: 1150;
   stroke-opacity: 1;
   fill-opacity: 1;
   fill-rule:evenodd;
   clip-rule:evenodd;
   fill:#f0f;
   transition: all 0.5s ease;
  }
.drawIn {
  stroke-dasharray: 1150;
  stroke-dashoffset: 1150;
  stroke-opacity: 1;
  fill-opacity: 1;
  animation: dashdrawFw 5s ease forwards;
 }

 .drawOut {
   stroke-dasharray: 1150;
   stroke-dashoffset: 0;
   stroke-opacity: 1;
   fill-opacity: 1;
   animation: dashdrawBw 4s ease forwards;
  }
@keyframes dashdrawFw {
  from
  {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    stroke-opacity: 0.2;
    fill-opacity: 0.1;

  }
  to
  {
    stroke-dasharray: 	1150;
    stroke-dashoffset: 0;
    stroke-opacity: 0.8;
    fill-opacity: 0.8;
  }
}
@keyframes dashdrawBw {
  from
  {
    stroke-dasharray: 1150;
    stroke-dashoffset: 0;
    stroke-opacity: 0.8;
    fill-opacity: 0.8;

  }
  to
  {
    stroke-dasharray: 	1150;
    stroke-dashoffset: 1150;
    stroke-opacity: 0.2;
    fill-opacity: 0.1;
  }
}
.st0{
  fill-rule:evenodd;
  clip-rule:evenodd;
  fill:#0f0;
  fill-opacity: 0.8;}
.st1{
  fill:none;
  stroke:#E6007E;
  stroke-width:4;
  stroke-opacity: 0.8;
  stroke-miterlimit:10;
}
.st0:hover,
.st1:hover {
 fill-opacity:0.4;
 stroke-opacity: 0.4;
 fill:#f0f !important;
 stroke:#0f0 !important;
 }

.scaleDownHor {
  transform: scale(0.7) !important;
  transition: all 3s ease;
  will-change: transform;
}

.scaleIn {
  transform: scale(1) !important;
  transition: transform 3s ease;
  will-change: transform;
}

.scaleOut {
  transform: scale(0) !important;
  transition: transform 5s ease;
  will-change: transform;
}

.fadeInBg {
  opacity: 0.8;
  transition: all 5s ease;
  will-change: opacity;
}
.fadeIn {
  opacity: 1;
  transition: all 5s ease;
  will-change: opacity;
}

.fadeOut {
  opacity: 0 !important;
  transition: all 5s ease;
  will-change: opacity;
}

.unblur {
  filter: blur(0px);
  will-change: filter;
}

.blur {
  filter: blur(2px);
  will-change: filter;
}

@media (min-width: 768px) {
  .hidden {
    transition: opacity 1s ease-out;
    will-change: opacity;
    opacity: 0 !important;
    transform: translate(0) scaleX(0.9) !important;
  }

  .visible {
    transition: opacity 1s ease-out;
    will-change: opacity;
    opacity: 1 !important;
    transform: translate(33.3%) scaleX(1) !important;
    transition: all 0.5s ease;
  }

  .visibleCover {
    /* box-shadow: 2px 2px 8px 8px rgba(255, 255, 255, 0.4) !important; */
    opacity: 1 !important;
    transform: scale(1) !important;
    transition: all 3s ease;
  }
}
@media (max-width: 768px) {
  .hidden {
    transition: opacity 1s ease-out;
    will-change: opacity;
    opacity: 0 !important;
    transform: translate(0) !important;
  }

  .visible {
    transition: opacity 1s ease-out;
    will-change: opacity;
    opacity: 1 !important;
    transform: translateY(-2vh) !important;
    transition: all 0.5s ease;
  }

  .mediaButtonsXSStart {
    flex-flow: wrap;
transform: scale(0.7);
margin-top: -15vh;
margin-bottom: -5vh;
  }

  .visibleCover {
    /* box-shadow: 2px 2px 8px 8px rgba(255, 255, 255, 0.4) !important; */
    opacity: 1 !important;
    transform: scale(1) !important;
    transition: all 1s ease;
  }
}
